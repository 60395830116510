import React, { useEffect, useState } from 'react';
import { Center, Stack, Button, Box, Text, Flex } from '@chakra-ui/react';

import TitleDescription from '../components/TitleDescription';
import RoomCode from '../components/RoomCode';
import PlayerItem from '../components/PlayerItem';
import useDestroyGameroom from '../hooks/useDestroyGameroom';
import useDestroyPlayer from '../hooks/useDestroyPlayer';
import useCableStore from '../hooks/useCableStore';
import { AWAITING_MESSAGE, GAMEROOM_KEY, PLAYER_INFO } from '../constants/key';
import { GAMEROOM_PICKING_NEW_PACK } from '../constants/events';
import paths from '../constants/paths';
import Modal from '../components/Modal';
import { openModal, closedModal } from '../utils/modal';
import DottedBorder from '../assets/dotted-border.svg';
import useGameroomStore from '../hooks/useGameroomStore';
import useNavigationStore from '../hooks/useNavigationStore';

const AwaitingPlayers = () => {
  const { navigateTo } = useNavigationStore();

  const [footerInfo, setFooterInfo] = useState(
    'Waiting for other players to join...'
  );

  const [modalInfo, setModalInfo] = useState(closedModal());
  const players = useGameroomStore((state) => state.gameroom?.players ?? []);

  if (useGameroomStore((state) => state.gameroom?.currentGame)) {
    navigateTo(paths.scores);
  }

  const maxPlayers = 50;
  const gameroomKey = localStorage.getItem(GAMEROOM_KEY);
  const {
    id: playerId,
    username,
    admin,
  } = JSON.parse(localStorage.getItem(PLAYER_INFO));
  const { setMessageHandler } = useCableStore();

  const { mutate: destroyGameroom } = useDestroyGameroom();

  const { mutate: destroyPlayer } = useDestroyPlayer();

  const handleLeave = async () => {
    if (admin) {
      destroyPlayer(
        { gameroomKey, playerId },
        { onSuccess: () => destroyGameroom({ gameroomKey }) }
      );
    } else {
      destroyPlayer({ gameroomKey, playerId }, {});
    }
  };

  const handleStartGame = () => {
    navigateTo(paths.packs);
  };

  useEffect(() => {
    setFooterInfo(
      localStorage.getItem(AWAITING_MESSAGE) ??
        'Waiting for other players to join...'
    );

    return () => {
      localStorage.removeItem(AWAITING_MESSAGE);
    };
  }, []);

  useEffect(() => {
    setMessageHandler(async (message) => {
      switch (message?.event) {
        case GAMEROOM_PICKING_NEW_PACK:
          localStorage.setItem(AWAITING_MESSAGE, 'The game is starting...');
          setFooterInfo(localStorage.getItem(AWAITING_MESSAGE));
          break;
        default:
      }
    });
  }, []);

  return (
    <Center height="100vh">
      <Flex align="center" direction="column" gap="2.4rem">
        <Stack
          bg="main.purple.darker"
          paddingY="2.4rem"
          paddingX="3.2rem"
          gap="1rem"
          borderRadius="2rem"
          height={admin ? '52.572rem' : '48.572rem'}
        >
          <Stack
            width="27.9rem"
            height="47.972rem"
            gap="3.2rem"
            alignItems="center"
          >
            <Box position="relative">
              <TitleDescription
                title="Players"
                description="After you start the game, no more players will be able to join."
              />
              <Text variant="playerCount">
                ({players.length}/{maxPlayers})
              </Text>
            </Box>
            <RoomCode code={gameroomKey} copyable />
            <Stack
              width="27.9rem"
              height="17.672rem"
              gap="0.8rem"
              css={{
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
              overflowY="scroll"
              overflowX="hidden"
            >
              {players.map((player, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <PlayerItem
                      username={player.username}
                      isCurrentPlayer={username === player.username}
                      isAdmin={player.admin}
                    />
                    {idx !== players.length - 1 ? (
                      <img src={DottedBorder} alt="dotted-border" />
                    ) : null}
                  </React.Fragment>
                );
              })}
            </Stack>
          </Stack>

          <Stack width="27.9rem" height="9rem" gap="0.8rem" justify="end">
            {admin && (
              <Button
                variant="solid"
                scheme="main.purple"
                onClick={handleStartGame}
              >
                {'start game'}
              </Button>
            )}
            <Button
              variant="outline"
              scheme="secondary.pink"
              onClick={() => setModalInfo(openModal('leave_game', handleLeave))}
            >
              {admin ? 'cancel game' : 'leave game'}
            </Button>
          </Stack>
        </Stack>
        {!admin && (
          <Text color="white" variant="footer">
            {footerInfo}
          </Text>
        )}
      </Flex>

      <Modal {...modalInfo} onClose={() => setModalInfo(closedModal())} />
    </Center>
  );
};

export default AwaitingPlayers;
