import { create } from 'zustand';

const useGameroomStore = create((set) => ({
  gameroom: null,

  addPlayerToGameroom: (player) =>
    set((state) => {
      const newGameroom = {
        ...state.gameroom,
        players: [...state.gameroom.players, player],
      };
      return { ...state, gameroom: newGameroom };
    }),

  addAnswerToGameroom: (answer) =>
    set((state) => {
      const { currentGame } = state.gameroom;
      const { rounds } = currentGame;
      const currentRoundIndex = rounds.length - 1;
      const currentRound = rounds[currentRoundIndex];

      const updatedRound = {
        ...currentRound,
        answers: [...currentRound.answers, answer],
      };

      const updatedRounds = [
        ...rounds.slice(0, currentRoundIndex),
        updatedRound,
        ...rounds.slice(currentRoundIndex + 1),
      ];

      return {
        ...state,
        gameroom: {
          ...state.gameroom,
          currentGame: { ...currentGame, rounds: updatedRounds },
        },
      };
    }),

  addCurrentGameToGameroom: (game) =>
    set((state) => {
      const newGameroom = {
        ...state.gameroom,
        currentGame: game,
        players: game.players,
      };
      return { ...state, gameroom: newGameroom };
    }),

  addRoundToGameroom: (round) =>
    set((state) => {
      const { rounds } = state.gameroom.currentGame;
      return {
        ...state,
        gameroom: {
          ...state.gameroom,
          currentGame: {
            ...state.gameroom.currentGame,
            rounds: [...rounds, round],
          },
        },
      };
    }),

  finishGameroomCurrentRound: (round) =>
    set((state) => {
      const { currentGame } = state.gameroom;
      const { rounds } = currentGame;
      const currentRoundIndex = rounds.length - 1;
      const updatedRounds = [
        ...rounds.slice(0, currentRoundIndex),
        round,
        ...rounds.slice(currentRoundIndex + 1),
      ];

      return {
        ...state,
        gameroom: {
          ...state.gameroom,
          currentGame: { ...currentGame, rounds: updatedRounds },
        },
      };
    }),

  removePlayerFromGameroom: (player) =>
    set((state) => {
      const newGameroom = {
        ...state.gameroom,
        players: state.gameroom.players.filter(
          (oldPlayer) => oldPlayer.username !== player.username
        ),
      };
      return { ...state, gameroom: newGameroom };
    }),
  updateGameroom: (newGameroom) =>
    set((state) => {
      return { ...state, gameroom: newGameroom };
    }),

  clearGameroom: set((state) => ({ ...state, gameroom: null })),
}));

export default useGameroomStore;
