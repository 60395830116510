import { useSuspenseQuery } from '@tanstack/react-query';

import { fetchGameroom } from '../api/gameroom';

export const fetchGameroomQuery = (gameroomKey) => ({
  queryKey: ['fetchGameroom', gameroomKey],
  queryFn: async () => {
    const data = await fetchGameroom(gameroomKey);

    if (data?.currenGame?.rounds) {
      data.currentGame.rounds = [...data.currentGame.rounds].sort(
        (a, b) => a.id - b.id
      );
    }

    return data;
  },
});

const useFetchGameroom = (gameroomKey) => {
  return useSuspenseQuery(fetchGameroomQuery(gameroomKey));
};

export default useFetchGameroom;
