import React from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Home from '../pages/Home';
import Components from '../pages/Components';
import CreateRoom from '../pages/CreateRoom';
import JoinRoom from '../pages/JoinRoom';
import Result from '../pages/Result';
import Round from '../pages/Round';
import Scores from '../pages/Scores';

import AwaitingPlayers from '../pages/AwaitingPlayers';
import PackSelection from '../pages/PackSelection';
import ConnectCableWrapper from '../components/ConnectCableWrapper';
import Answers from '../pages/Answers';
import GameroomWrapper from '../components/GameroomWrapper';

import paths from '../constants/paths';
import NavigationWrapper from '../components/NavigationWrapper';

const queryClient = new QueryClient();

const renderCableRoutes = () => {
  return (
    <Route element={<ConnectCableWrapper />}>
      <Route element={<GameroomWrapper />}>
        <Route path={paths.awaiting} element={<AwaitingPlayers />} />
        <Route path={paths.result} element={<Result />} />
        <Route path={paths.round} element={<Round />} />
        <Route path={paths.answers} element={<Answers />} />
        <Route path={paths.packs} element={<PackSelection />} />
        <Route path={paths.scores} element={<Scores />} />
      </Route>
    </Route>
  );
};

const renderRoutesWithoutCable = () => {
  return (
    <Route>
      <Route path="/" element={<Home />} />
      <Route path="/components" element={<Components />} />
      <Route path={paths.joinGameroom} element={<JoinRoom />} />
      <Route path={paths.createGameroom} element={<CreateRoom />} />
    </Route>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<NavigationWrapper />}>
      {renderRoutesWithoutCable()}
      {renderCableRoutes()}
    </Route>
  )
);

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
};

export default App;
