import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'icon',
  'label',
  'username',
  'userScoreContainer',
  'iconBox',
  'nameContainer',
  'value',
]);

const PlayerItem = helpers.defineMultiStyleConfig({
  baseStyle: (props) => ({
    container: {
      align: 'center',
      width: '27.9rem',
      height: '2.2rem',
      gap: '0.4rem',
    },
    nameContainer: {
      align: 'center',
    },
    userScoreContainer: {
      align: 'center',
      justify: 'space-between',
      width: '25.3rem',
    },
    iconBox: {
      align: 'center',
      width: '2.2rem',
      height: '2.2rem',
    },
    icon: {
      width: '100%',
      height: '100%',
      alt: 'player-icon',
      paddingY: '0.2rem',
    },
    username: {
      fontWeight: props.isCurrentPlayer ? 'bold' : 400,
      fontSize: '1.4rem',
      textColor: 'main.white',
    },
    label: {
      fontSize: '1.4rem',
      textColor: 'secondary.yellow.base',
    },
    value: {
      fontWeight: 600,
      fontSize: '1.8rem',
      lineHeight: '1.5rem',
      marginX: '0.4rem',
    },
  }),

  variants: {
    answer: (props) => {
      const iconProps = {
        objectFit: 'fill',
        alt: 'answer-data',
        marginY: '0.4rem',
      };

      const value = {
        unanswered: {
          textAlign: 'end',
          fontWeight: 700,
          fontSize: '1.8rem',
          lineHeight: '1.958rem',
          color: 'secondary.lilac.base',
        },
        answered: {
          textAlign: 'end',
          fontWeight: 400,
          fontSize: '1.4rem',
          lineHeight: '1.523rem',
          textColor: props.persona?.color ?? 'secondary.lilac.base',
        },
        correct: {
          ...iconProps,
          marginX: '0.4rem',
        },
        incorrect: {
          ...iconProps,
          marginX: '0.538rem',
        },
      };

      return {
        value: value[props.status],
        container: {
          width: '35.5rem',
          height: '2.472rem',
        },
        icon: {
          color: 'main.purple.light',
        },
        userScoreContainer: {
          height: '1.5rem',
          width: 'full',
        },
      };
    },
    leaderBoard: (props) => {
      const colors = {
        firstPlace: 'secondary.yellow.base',
        lastPlace: 'secondary.pink.light',
        other: props.isCurrentPlayer
          ? 'secondary.yellow.base'
          : 'secondary.lilac.base',
      };

      return {
        value: {
          fontWeight: 600,
          fontSize: '1.8rem',
          lineHeight: '1.958rem',
          textColor: colors[props.position],
        },
      };
    },
  },
});

export default PlayerItem;
