import React, { Fragment } from 'react';
import { Divider, Stack, useMultiStyleConfig } from '@chakra-ui/react';

import PlayerItem from './PlayerItem';

import { PlayerListShape } from '@utils/propsShape';

const PlayerList = ({ players, variant }) => {
  const style = useMultiStyleConfig('PlayerList', { variant });
  return (
    <Stack {...style.container}>
      {players.map((player, idx) => {
        return (
          <Fragment key={idx}>
            <PlayerItem {...player} />
            <Divider
              borderWidth="0.1rem"
              variant="dashed"
              borderColor="main.purple.light"
            />
          </Fragment>
        );
      })}
    </Stack>
  );
};

PlayerList.propTypes = PlayerListShape;

export default PlayerList;
