import React from 'react';
import { useMultiStyleConfig, Flex, Text } from '@chakra-ui/react';

import Crown from './icons/Crown';
import AdminIcon from './icons/Admin';
import Loser from './icons/Loser';
import Correct from './icons/Correct';
import Incorrect from './icons/Incorrect';
import PlayerIcon from './icons/Player';

import { PlayerItemShape } from '@utils/propsShape';

const PlayerItem = ({
  username,
  isAdmin,
  isCurrentPlayer,
  roomProps = {},
  variant,
}) => {
  const style = useMultiStyleConfig('PlayerItem', {
    variant,
    isCurrentPlayer,
    ...roomProps,
  });

  const answerData = {
    unanswered: <Text {...style.value}> -</Text>,
    answered: <Text {...style.value}>{roomProps.persona?.name ?? ''}</Text>,
    correct: <Correct color="secondary.green" {...style.value} />,
    incorrect: <Incorrect color="secondary.pink.light" {...style.value} />,
  };

  const leaderBoardIcons = {
    firstPlace: <Crown color="secondary.yellow.base" {...style.icon} />,
    lastPlace: <Loser color="secondary.pink.light" {...style.icon} />,
  };

  const getIcon = () => {
    const color = isCurrentPlayer
      ? 'secondary.yellow.base'
      : 'main.purple.light';

    if (variant === 'leaderBoard' && roomProps.position !== 'other') {
      return leaderBoardIcons[roomProps.position];
    }

    if (isAdmin) {
      return <AdminIcon color={color} {...style.icon} />;
    }

    return <PlayerIcon color={color} {...style.icon} />;
  };

  const getValue = () => {
    if (variant !== 'answer') {
      return <Text {...style.value}>{roomProps.value}</Text>;
    }

    return answerData[roomProps.status];
  };

  return (
    <Flex {...style.container}>
      <Flex {...style.iconBox}>{getIcon()}</Flex>
      <Flex {...style.userScoreContainer}>
        <Flex {...style.nameContainer}>
          <Text {...style.username}>{username}</Text>
        </Flex>
        {getValue()}
      </Flex>
    </Flex>
  );
};

PlayerItem.propTypes = PlayerItemShape;

export default PlayerItem;
