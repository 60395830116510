import React, { useState } from 'react';
import { Stack, Button, Center } from '@chakra-ui/react';

import Loading from '../components/Loading';
import TitleDescription from '../components/TitleDescription';
import Input from '../components/Input';
import useCreateGameroom from '../hooks/useCreateGameroom';
import useNavigationStore from '../hooks/useNavigationStore';

import paths from '../constants/paths';

const CreateRoom = () => {
  const { navigateTo } = useNavigationStore();
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(null);

  const { mutate: createRoom, isPending: isCreatingRoom } = useCreateGameroom({
    onSuccess: () => {
      navigateTo(paths.awaiting);
    },
    onError: (error) =>
      setUsernameError(error?.response?.data.errors?.username[0]),
  });

  const onUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleCreateRoom = async () => {
    createRoom(username);
  };

  return (
    <Center height="100vh">
      {isCreatingRoom ? (
        <Loading title="Creating" description="Wait a few moments." />
      ) : (
        <Stack
          bg="main.purple.darker"
          gap="1rem"
          width="34.3rem"
          height="35.6rem"
          borderRadius="2rem"
          paddingY="2.4rem"
          paddingX="3.2rem"
        >
          <Stack width="27.9rem" height="30.8rem" gap="4.8rem">
            <Stack width="27.9rem" height="17rem" gap="3.2rem">
              <TitleDescription
                title="Create room"
                description="Insert your nickname to start inviting other players."
              />
              <Input
                label="Nickname"
                placeholder="gustavinho"
                value={username}
                onChange={onUsernameChange}
                errors={usernameError}
              />
            </Stack>
            <Stack width="27.9rem" height="9rem" gap="0.8rem">
              <Button
                variant="solid"
                scheme="main.purple"
                onClick={handleCreateRoom}
              >
                CREATE ROOM
              </Button>
              <Button
                variant="outline"
                scheme="main.purple"
                onClick={() => navigateTo(paths.root)}
              >
                BACK
              </Button>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Center>
  );
};

export default CreateRoom;
