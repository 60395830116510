import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { GAMEROOM_KEY } from '../constants/key';

import useGameroomStore from '../hooks/useGameroomStore';
import useNavigationStore from '../hooks/useNavigationStore';
import useFetchGameroom from '../hooks/useFetchGameroom';

import Loading from './Loading';

const GameroomWrapper = () => {
  const gameroomKey = localStorage.getItem(GAMEROOM_KEY);
  const { data: fetchedGameroom } = useFetchGameroom(gameroomKey);
  const { gameroom, updateGameroom } = useGameroomStore();
  const { isNavigating, setIsNavigating } = useNavigationStore();
  const location = useLocation();

  useEffect(() => {
    if (gameroomKey) {
      updateGameroom(fetchedGameroom);
    }
  }, [fetchedGameroom, gameroomKey, updateGameroom]);

  useEffect(() => {
    setIsNavigating(false);
  }, [location, setIsNavigating]);

  if (!gameroom || isNavigating) {
    return <Loading title="Loading gameroom" description="" />;
  }

  return <Outlet />;
};

export default GameroomWrapper;
