import React, { useEffect, useState, useMemo, Fragment } from 'react';
import {
  Center,
  Stack,
  Button,
  Box,
  Text,
  Flex,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import PlayerItem from '../components/PlayerItem';
import PlayerList from '../components/PlayerList';
import Modal from '../components/Modal';

import useDestroyGameroom from '../hooks/useDestroyGameroom';
import useDestroyPlayer from '../hooks/useDestroyPlayer';
import useCableStore from '../hooks/useCableStore';
import useGameroomStore from '../hooks/useGameroomStore';
import useNavigationStore from '../hooks/useNavigationStore';

import { AWAITING_MESSAGE, GAMEROOM_KEY, PLAYER_INFO } from '../constants/key';
import { GAMEROOM_PICKING_NEW_PACK } from '../constants/events';
import paths from '../constants/paths';
import { openModal, closedModal } from '../utils/modal';
import LeaveGameButton from '../components/LeaveGameButton';

const Scores = () => {
  const { navigateTo } = useNavigationStore();
  const [footerInfo, setFooterInfo] = useState('');
  const [modalInfo, setModalInfo] = useState(closedModal());
  const styles = useMultiStyleConfig('Leaderboard');

  const scores = useGameroomStore(
    (state) => state.gameroom?.currentGame?.scores ?? {}
  );

  const players = useGameroomStore((state) => {
    const gameroomPlayers = state.gameroom?.players ?? [];
    const currentGamePlayers = state.gameroom?.currentGame?.players ?? [];

    const allPlayers = [...gameroomPlayers, ...currentGamePlayers].filter(
      (player, index, self) =>
        index === self.findIndex((p) => p.id === player.id)
    );

    return allPlayers.filter((player) => {
      const isInScores = player.id in scores;
      const hasLeft = !!player.leftGameroomAt;

      return !hasLeft || isInScores;
    });
  });

  const gameroomKey = localStorage.getItem(GAMEROOM_KEY);
  const {
    id: playerId,
    username,
    admin,
  } = JSON.parse(localStorage.getItem(PLAYER_INFO));

  const { setMessageHandler } = useCableStore();

  const { mutate: destroyGameroom } = useDestroyGameroom({
    onSuccess: () => {
      navigateTo(paths.root);
    },
  });

  const { mutate: destroyPlayer } = useDestroyPlayer({});

  const handleLeave = async () => {
    if (admin) {
      destroyPlayer(
        { gameroomKey, playerId },
        { onSuccess: () => destroyGameroom({ gameroomKey }) }
      );
    } else {
      destroyPlayer({ gameroomKey, playerId }, {});
    }
  };

  const handlePlayAgain = () => {
    navigateTo(paths.packs);
  };

  const hasValidScore = (p) =>
    scores[p.id] !== null && scores[p.id] !== undefined;

  const sortedPlayers = useMemo(() => {
    const validPlayers = players.filter(hasValidScore);
    const invalidPlayers = players.filter((p) => !hasValidScore(p));

    const sortedValidPlayers = [...validPlayers].sort(
      (a, b) => scores[b.id] - scores[a.id]
    );

    const positionedValidPlayers = sortedValidPlayers.map((p, index, array) => {
      let position;
      if (index === 0) {
        position = 'firstPlace';
      } else if (index === array.length - 1) {
        position = 'lastPlace';
      } else {
        position = 'other';
      }
      return {
        ...p,
        isCurrentPlayer: username === p.username,
        roomProps: {
          value: scores[p.id] ?? '-',
          position,
        },
        isAdmin: p.admin,
        variant: 'leaderBoard',
      };
    });

    const positionedInvalidPlayers = invalidPlayers.map((p) => ({
      ...p,
      isCurrentPlayer: username === p.username,
      roomProps: {
        value: '-',
        position: 'other',
      },
      isAdmin: p.admin,
      variant: 'leaderBoard',
    }));

    return [...positionedValidPlayers, ...positionedInvalidPlayers];
  }, [players]);

  const firstPlacePlayer = sortedPlayers.find(
    (player) => player.roomProps.position === 'firstPlace'
  );

  const otherPlayers = sortedPlayers.filter(
    (player) => player.roomProps.position !== 'firstPlace'
  );

  useEffect(() => {
    setMessageHandler((message) => {
      switch (message?.event) {
        case GAMEROOM_PICKING_NEW_PACK:
          localStorage.setItem(AWAITING_MESSAGE, 'The game is starting...');
          setFooterInfo(localStorage.getItem(AWAITING_MESSAGE));
          break;
        default:
      }
    });
  }, []);

  return (
    <Center height="100vh">
      <Flex sx={{ ...styles.container }}>
        <Stack sx={{ ...styles.background }}>
          <Stack sx={{ ...styles.content }}>
            <Stack sx={{ ...styles.title }}>
              <Text variant="completed">Pack completed</Text>
              <Text variant="pageTitle">Scores</Text>
            </Stack>
            <Flex sx={styles.playerList}>
              {firstPlacePlayer && (
                <Box sx={styles.winner}>
                  <PlayerItem {...firstPlacePlayer} variant="leaderBoard" />
                </Box>
              )}
            </Flex>

            <PlayerList variant="leaderBoard" players={otherPlayers} />

            {admin && (
              <Stack sx={styles.adminButtons}>
                <Button
                  variant="solid"
                  scheme="main.purple"
                  onClick={handlePlayAgain}
                >
                  Play again
                </Button>
                <Button
                  variant="outline"
                  scheme="secondary.pink"
                  onClick={handleLeave}
                >
                  Close room
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
        {!admin && (
          <>
            <Text sx={styles.footer} variant="footer">
              {footerInfo}
            </Text>
            <LeaveGameButton
              onClick={() => setModalInfo(openModal('leave_game', handleLeave))}
            />
          </>
        )}
      </Flex>

      <Modal {...modalInfo} onClose={() => setModalInfo(closedModal())} />
    </Center>
  );
};

export default Scores;
