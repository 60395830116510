import { create } from 'zustand';
import { createConsumer } from '@rails/actioncable';

import { DEVICE_UUID } from '../constants/key';

const useCableStore = create((set) => ({
  consumer: null,
  channel: null,
  message: null,
  messageHandler: () => {},

  create: () =>
    set((state) => {
      const deviceUuid = localStorage.getItem(DEVICE_UUID);
      if (!state.consumer && deviceUuid) {
        const consumer = createConsumer(
          `${import.meta.env.VITE_API_WEBSOCKET_URL}?device_uuid=${deviceUuid}`
        );

        return { ...state, consumer };
      }

      return state;
    }),

  setMessageHandler: (messageHandler) =>
    set((state) => ({ ...state, messageHandler })),

  clearMessage: () =>
    set((state) => {
      return { ...state, message: null };
    }),

  subscribe: (gameroomKey, playerId) =>
    set((state) => {
      if (!state.channel) {
        const channel = state.consumer.subscriptions.create(
          {
            channel: 'GameroomChannel',
            gameroom_key: gameroomKey,
            player_id: playerId,
          },
          { received: state.setMessage }
        );
        return { ...state, channel };
      }

      return state;
    }),

  destroy: () =>
    set((state) => {
      if (state.consumer) {
        state.consumer.disconnect();
      }

      return {
        messageHandler: () => {},
        channel: null,
        message: null,
        consumer: null,
      };
    }),

  setMessage: (message) => set((state) => ({ ...state, message })),
}));

export default useCableStore;
